import React from 'react'

import Header from '../components/Header'
import Layout from '../layouts'

function NotFoundPage({ location }) {
  return (
    <Layout location={location}>
      <Header
        title={
          <span>
            <strong>Oups...</strong> Cette page n'existe pas
          </span>
        }
      />
    </Layout>
  )
}

export default NotFoundPage
