import React from 'react'
import PropTypes from 'prop-types'

function Header({ title, scrollToContent, buttonContent }) {
  return (
    <section id="header">
      <div id="triangle-topright" />
      <div className="inner">
        <h1>{title}</h1>
      </div>
    </section>
  )
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  scrollToContent: PropTypes.func,
  buttonContent: PropTypes.string,
}

export default Header
